/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header} from 'components/lib';
import Style from './app.module.scss';

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);

    return (
    <Fragment>
      <AppNav
        items={[
          { label: 'Event Logs', icon: 'database', link: '/eventlogs' },
          { label: 'Models', icon: 'package', link: '/models' },
          { label: 'Account', icon: 'user', link: '/account', },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ props.title === 'Visualizer' ? Style.apppm : Style.app }>

        {props.title != 'Visualizer'
          ? <Header title={ props.title }></Header>
          : ''
        }

        { <props.children {...props.data }/> }

      </main>
    </Fragment>
  );
}