import * as React from 'react';
import {QA} from './qa'
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import {ExpandMore} from '@material-ui/icons';

export function FAQSupport() {
  return (
    <Accordion disableGutters={false}>
      <AccordionSummary
        expandIcon={<ExpandMore style={{ fill: 'white' }}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
            backgroundColor: "#197BCD",
            color: "white",
            fontFamily: "Helvetica",
            fontWeight: "fontWeightBold",
            mb: "12px"
        }}
        >
        <Typography>Support and services...</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <QA question='Does Metricus provide services to assist in the generation of event logs required?' answer='Yes.  Please contact support@metricus.com if you’d like to learn more about Metricus Services'/>
      <QA question='Can I request functionality that does not exist?' answer='Absolutely!   Metricus looks forward to working proactively with customers to both meet your expectations, and improve the overall product'/>
      <QA question='How often are software upgrades applied ?' answer='The Metricus Process Miner leverages an advanced SaaS architecture that enables new features to be rolled out continually.  At a minimum, new features are released monthly, along with an online video explaining the features.'/>
    </AccordionDetails>
    </Accordion>
  );
}