
import React from 'react';
import { Row, PurchaseForm } from 'components/lib';

export function Purchase(props){

  return(
    <PurchaseForm />
  )
}
