import React from 'react';
var HTMLContent = `<html>
<body>
    <div class="container">
      <div class="top">
        <div class="toggle-btn">
          <span style="margin: 0.8em; font-weight: bold">Monthly</span>
          <label class="switch">
            <input type="checkbox" id="checkbox" onclick="
            var billing_cycle = 'monthly'
            if (document.getElementById('checkbox').checked == true) {billing_cycle = 'annual'}

            var currency = document.getElementById('currency').value;
            var monthly_usd = document.getElementsByClassName('monthly_usd');
            var annual_usd = document.getElementsByClassName('annual_usd');
            var monthly_eur = document.getElementsByClassName('monthly_eur');
            var annual_eur = document.getElementsByClassName('annual_eur');
            var monthly_gbp = document.getElementsByClassName('monthly_gbp');
            var annual_gbp = document.getElementsByClassName('annual_gbp');
            var monthly_aud = document.getElementsByClassName('monthly_aud');
            var annual_aud = document.getElementsByClassName('annual_aud');

            if (billing_cycle == 'monthly' && currency == '$ USD') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'block';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }
            if (billing_cycle == 'annual' && currency == '$ USD') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'block';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }
            if (billing_cycle == 'monthly' && currency == '€ EUR') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'block';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }
            if (billing_cycle == 'annual' && currency == '€ EUR') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'block';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }
            if (billing_cycle == 'monthly' && currency == '$ AUD') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'block';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }
            if (billing_cycle == 'annual' && currency == '$ AUD') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'block';}
            }

            if (billing_cycle == 'monthly' && currency == '£ GBP') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'block';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }
            if (billing_cycle == 'annual' && currency == '£ GBP') {
              for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
              for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
              for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
              for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
              for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
              for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'block';}
              for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
              for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
            }


            "  ; />
            <span class="slider round"></span>
          </label>
          <span style="margin: 0.8em; font-weight: bold">Annual</span></div>
          <div class="input-group mb-2 mr-sm-2 mb-sm-0">
            <div class="input-group-addon currency-addon">
                  <select id= "currency" class="currency-selector" onchange="
                  var billing_cycle = 'monthly'
                  if (document.getElementById('checkbox').checked == true) {billing_cycle = 'annual'}
                  
                  var currency = document.getElementById('currency').value;
                  var monthly_usd = document.getElementsByClassName('monthly_usd');
                  var annual_usd = document.getElementsByClassName('annual_usd');
                  var monthly_eur = document.getElementsByClassName('monthly_eur');
                  var annual_eur = document.getElementsByClassName('annual_eur');
                  var monthly_gbp = document.getElementsByClassName('monthly_gbp');
                  var annual_gbp = document.getElementsByClassName('annual_gbp');
                  var monthly_aud = document.getElementsByClassName('monthly_aud');
                  var annual_aud = document.getElementsByClassName('annual_aud');
  
                  if (billing_cycle == 'monthly' && currency == '$ USD') {
                    
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'block';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                  if (billing_cycle == 'annual' && currency == '$ USD') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'block';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                  if (billing_cycle == 'monthly' && currency == '€ EUR') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'block';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                  if (billing_cycle == 'annual' && currency == '€ EUR') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'block';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                  if (billing_cycle == 'monthly' && currency == '$ AUD') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'block';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                  if (billing_cycle == 'annual' && currency == '$ AUD') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'block';}
                  }
  
                  if (billing_cycle == 'monthly' && currency == '£ GBP') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'block';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'none';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                  if (billing_cycle == 'annual' && currency == '£ GBP') {
                    for (var i = 0; i < monthly_usd.length; i++) {monthly_usd[i].style.display = 'none';}
                    for (var i = 0; i < annual_usd.length; i++) {annual_usd[i].style.display = 'none';}
                    for (var i = 0; i < monthly_eur.length; i++) {monthly_eur[i].style.display = 'none';}
                    for (var i = 0; i < annual_eur.length; i++) {annual_eur[i].style.display = 'none';}
                    for (var i = 0; i < monthly_gbp.length; i++) {monthly_gbp[i].style.display = 'none';}
                    for (var i = 0; i < annual_gbp.length; i++) {annual_gbp[i].style.display = 'block';}
                    for (var i = 0; i < monthly_aud.length; i++) {monthly_aud[i].style.display = 'none';}
                    for (var i = 0; i < annual_aud.length; i++) {annual_aud[i].style.display = 'none';}
                  }
                 
                                      
                  ">
                    <option selected>$ USD</option>
                    <option>€ EUR</option>
                    <option>£ GBP</option>
                    <option>$ AUD</option>
                </select>
            </div>
          </div>          
      </div>
  
      <div class="package-container">
        <div class="packages">
          <h1>Free</h1>
          <h2 id="monthly_usd" class="monthly_usd">&dollar;0.00</h2>
          <h2 id="annual_usd" class="annual_usd">&dollar;0.00</h2>
          <h2 id="monthly_eur" class="monthly_eur">&euro;0.00</h2>
          <h2 id="annual_eur" class="annual_eur">&euro;0.00</h2>
          <h2 id="monthly_gpb" class="monthly_gbp">&#163;0.00</h2>
          <h2 id="annual_gpb" class="annual_gbp">&#163;0.00</h2>
          <h2 id="monthly_aud" class="monthly_aud">&dollar;0.00</h2>
          <h2 id="annual_aud" class="annual_aud">&dollar;0.00</h2>
          <ul class="plist">
            <li class="first">5K Events</li>
            <li>Metricus Process Miner</li>
            <li>Event Log Transformation</li>
            <li>Sample Event Logs</li>
            <li>Email support</li>
          </ul>
          <a href="#" class="button button1" onclick="window.location.href='./signup';">Signup</a>
        </div>
        <div class="packages">
          <h1>Standard</h1>
          <h2 id="monthly_usd" class="monthly_usd">&dollar;480</h2>
          <h2 id="annual_usd" class="annual_usd">&dollar;4,800</h2>
          <h2 id="monthly_eur" class="monthly_eur">&euro;460</h2>
          <h2 id="annual_eur" class="annual_eur">&euro;4,600</h2>
          <h2 id="monthly_gpb" class="monthly_gbp">&#163;380</h2>
          <h2 id="annual_gpb" class="annual_gbp">&#163;3,800</h2>
          <h2 id="monthly_aud" class="monthly_aud">&dollar;680</h2>
          <h2 id="annual_aud" class="annual_aud">&dollar;6,800</h2>          
          <ul class="plist">
          <li class="first">500K Events</li>
          <li>Metricus Process Miner</li>
          <li>Event Log Transformation</li>
          <li>Event Log Storage</li>
          <li>Email support</li>
          </ul>
          <a href="#" class="button button1" onclick="window.location.href='./signup';">Signup</a>
        </div>
        <div class="packages">
          <h1>Enterprise</h1>
          <h2 id="monthly_usd" class="monthly_usd">&dollar;1,920</h2>
          <h2 id="annual_usd" class="annual_usd">&dollar;19,200</h2>
          <h2 id="monthly_eur" class="monthly_eur">&euro;1,840</h2>
          <h2 id="annual_eur" class="annual_eur">&euro;18,400</h2>
          <h2 id="monthly_gpb" class="monthly_gbp">&#163;1,520</h2>
          <h2 id="annual_gpb" class="annual_gbp">&#163;15,200</h2>
          <h2 id="monthly_aud" class="monthly_aud">&dollar;2,720</h2>
          <h2 id="annual_aud" class="annual_aud">&dollar;27,200</h2>          
          <ul class="plist">
            <li class="first">1M Events</li>
            <li>Metricus Process Miner</li>
            <li>Event Log Transformation</li>
            <li>Event Log Storage</li>
            <li>5 users per account</li>
            <li>Team Collaboration</li>
            <li>Email support</li>            
          </ul>
          <a href="#" class="button button1" onclick="window.location.href='./signup';">Signup</a>
        </div>
      </div>
    </div>


  </body>
  <head>
    <style>
    @import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");

    .currency-selector {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-left: .5rem;
        border:0px;
        outline:0px;
        background: transparent;
        
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='1024' height='640'><path d='M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z'></path></svg>") 90%/12px 6px no-repeat;
        
        font-family: Helvetica;
        font-weight: bold;
        color: #197BCD;
      }
      
      
      .currency-addon {
        width: 6em;
        height: 2.5em;
        top: 3.0em;
        right: 5em;
        text-align: left;
        position: absolute;
      }

    body {
      background-color: #f4f4f4;
      font-family: Helvetica;
    }
   
    .selector 
    {
        all: initial;
    }
        
    .container {
      width: 100%;
    }
    
    .annual_usd {
        display: none;
    }
    .monthly_eur {
        display: none;
    }
    .annual_eur {
        display: none;
    }
    .monthly_gbp {
        display: none;
    }
    .annual_gbp {
        display: none;
    }
    .monthly_aud {
        display: none;
    }
    .annual_aud {
        display: none;
    }

    .packages {
      margin: 20px;
      width: 300px;
      padding-bottom: 1.5em;
      height: 100%;
      background-color: #197BCD;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border-radius: 20px;
      box-shadow: 0 19px 38px rgba(30, 35, 33, 1), 0 15px 12px rgba(30, 35, 33, 0.2);
      flex-wrap: wrap;
      color: #f4f4f4;
    }
    
    h1,
    h2 {
      font-size: 2.2em;
      margin: 10px;
    }
    
    .plist li  {
      font-size: 20px;
      list-style: none;
      border-bottom: 1px solid #f4f4f4;
      border-left: 0px;
      border-right: 0px;
      padding-inline-start: 0;
      border-width: 1px;
      padding: 10px;
    }
    
    .first {
      margin-top: 20px;
      border-top: 1px solid #f4f4f4;
    }
    
    .list {
      width: 80%;
    }
    
    ol,
    ul {
      padding: 0;
    }
    
    .top {
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    input,
    label {
      display: inline-block;
      vertical-align: middle;
      margin: 10px 0;
    }
    
    .button {
      padding: 10px 30px;
      text-decoration: none;
      font-size: 1.4em;
      margin: 15px 15px;
      border-radius: 50px;
      color: #f4f4f4;
      transition: all 0.3s ease 0s;
    }
    
    .button:hover {
      transform: scale(1.2);
    }
    
    .button1 {
      background-color: #00cc99;
      box-shadow: 0 0 10px 0 #00cc99 inset, 0 0 20px 2px #00cc99;
    }
    
    .button2 {
      background-color: #ff007c;
      box-shadow: 0 0 10px 0 #ff007c inset, 0 0 20px 2px #ff007c;
    }
    
    .button3 {
      background-color: #ffae42;
      box-shadow: 0 0 10px 0 #ffae42 inset, 0 0 20px 2px #ffae42;
    }
    
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }
    
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #197BCD;
      -webkit-transition: 0.4s;
    
      box-shadow: 2px 6px 25px #197BCD;
      transform: translate(0px, 0px);
      transition: 0.6s ease transform, 0.6s box-shadow;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    
    input:checked + .slider {
      background-color: #197BCD;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #197BCD;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
    
    .package-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    
  
    </style>
</head>
    
  `

/* eslint-disable */

export const PricePlans = () => {

        return (
            <div>
                   <div dangerouslySetInnerHTML={{__html: HTMLContent}} />
            </div>       
        );

};

