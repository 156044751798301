import * as React from 'react';
import {QA} from './qa'
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import {ExpandMore} from '@material-ui/icons';

export function FAQPricing() {
  return (
    <Accordion disableGutters={false}>
      <AccordionSummary
        expandIcon={<ExpandMore style={{ fill: 'white' }}/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
            backgroundColor: "#197BCD",
            color: "white",
            fontFamily: "Helvetica",
            fontWeight: "fontWeightBold",
            mb: "12px"
        }}
        >
        <Typography>Plans and payments...</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <QA question='How long does a free trial last and are there any restrictions?' answer='A free trial lasts for 1 month.  The number of events is restricted to 5,000 and event logs cannot be saved to the server.   There are no restrictions in terms of Process Mining functionality'/>
      <QA question='Can I request an extension of a free trial or increase in the event log size?' answer='Yes.  Just send a request to support@metricus.com'/>
      <QA question='Can a free trial be extended to a subscription license without having to recreate an account' answer='Yes.   A free trial can be upgraded at any stage during the free trial period to a paid subscription'/>
      <QA question='What payment system is used for subscriptions?' answer='Metricus uses Stripe for processing payments'/>
      <QA question='Can I purchase a subscription for 2 or 3 months?' answer='Yes.  If you require Metricus for a 2 months project, we can provide a single invoice '/>
      <QA question='If a subscription is cancelled, is there a refund for the unused component' answer='Once a subscription payment is processed, the amount paid is non-refundable'/>
      <QA question='Can the currency of a subscription be changed  ' answer='When a subscription is due to renewal, the subscription plan can be changed to any other plan or currency'/>      
      <QA question='Can a subscription plan be downgraded during renewal' answer='When a subscription is due to renewal, the subscription plan can be changed to any other plan or currency'/>      
      <QA question='Is an on-premise version available?' answer='Yes. Please contact support@metricus.com for more information'/>      
    </AccordionDetails>
    </Accordion>
  );
}