import {React, useState, useContext, useCallback} from 'react';
import {ViewContext, Animate, useAPI, useNavigate, Loader} from 'components/lib';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import './visualizer.css'

var data = []

export function Models(props){
  
  const getContextMenuItems = useCallback((params) => {
    var result = [

      {
        // custom item
        name: 'Visualizer',
        action: function () {
          navigate({pathname: '/visualizer', search: '?model_id=' + params.node.data.id + '&event_log_id=' + params.node.data.event_log_id + '&sample=' + params.node.data.sample})
        },
        icon:
          '<img src="https://metricuspm.blob.core.windows.net/processminer/PO_Explorer.png" />',
      },
      {
        // custom item
        name: 'Delete',
        action: function () {
          DeleteModel(params.node.data.id);
        },
        icon:
          '<img src="https://metricuspm.blob.core.windows.net/processminer/PO_Delete.png"/>',
      },
      'separator',
      'export'
    ];
    return result;
  }, []);
  
  function dateFormatter(params) {
    if (!params.value) {return}
    var dateAsString = params.data.date_created;
    var year = dateAsString.substring(0,4)
    var month = dateAsString.substring(5,7)
    var day = dateAsString.substring(8,10)
    var hour = dateAsString.substring(11,13)
    var minute = dateAsString.substring(14,16)
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }

  function integerFormatter(params) {
    var x = params.value
    if (!x) {return}
    return Number(x).toLocaleString()
  }

  const onFirstDataRendered  = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeAllColumns();
  };

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  }

  const doubleClick = (params) => {
    navigate({pathname: '/visualizer', search: '?model_id=' + params.node.data.id + '&event_log_id=' + params.node.data.event_log_id + '&sample=' + params.node.data.sample})
   }

  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);

  const DeleteModel = (modelID) => {
    viewContext.modal.show({
      title: 'Delete Model',
      form: {},
      buttonText: 'Delete',
      url: `/api/model/delete/${modelID}`,
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete the process model? ' 

    }, () => {
      console.log (data)
  
      for (var i = 0; i < data.data.models.length; i++) {
        if (data.data.models[i].id === modelID) {
          data.data.models.splice(i,1)
        }
      }
    });

  }

  const models = [];
  data = useAPI('/api/model/retrieve_all')
 
  if (data.data !== null && data.data !== undefined) {
    for (var i = 0; i < data.data.models.length; i++) {
      models.push({
        id: data.data.models[i].id,
        event_log_id: data.data.models[i].event_log_id,
        category: data.data.models[i].category,
        name: data.data.models[i].name,
        description: data.data.models[i].description,
        event_log: data.data.models[i].event_log,
        owner_name: data.data.models[i].owner_name,
        date_created: data.data.models[i].date_created,
        sample: data.data.models[i].sample ,
        events: data.data.models[i].events,
        tasks: data.data.models[i].tasks,
        events_filtered: data.data.models[i].events_filtered,
        tasks_filtered: data.data.models[i].tasks_filtered,
        events_sliced: data.data.models[i].events_sliced,
        tasks_sliced: data.data.models[i].tasks_sliced,
        filters: data.data.models[i].filters,
        slicers: data.data.models[i].slicers,
        comments: data.data.models[i].comments,
        visualizer_settings: data.data.models[i].visualizer_settings,
        compliance_rules: data.data.models[i].compliance_rules,
        automation_rules: data.data.models[i].automation_rules,
        cost_rules: data.data.models[i].cost_rules
      });
    }
  }
  models.sort((a, b) => a.category.localeCompare(b.category) || a.name.localeCompare(b.name))

    const [columnDefs] = useState([
    { field: 'id', hide: true,  },
    { field: 'category', rowGroup: true, sortable: true, hide: true},
    { field: 'name', 
      headerName: 'Name', 
      sortable: true, 
      filter: true, 
      resizable: true,  
    },
    { field: 'description', headerName: 'Description', sortable: true, filter: true, resizable: true },
    { field: 'event_log' , headerName: 'Event Log', sortable: true, filter: true, resizable: true},
    { field: 'owner_name' , headerName: 'Owner', sortable: true, filter: true, resizable: true },
    { field: 'date_created', headerName: 'Create Time' , valueFormatter: dateFormatter, sortable: false, filter: true, resizable: true},
    { field: 'events', headerName: "Events",  sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'tasks', headerName: "Tasks",  sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'events_filtered', headerName: "Events Filtered",  sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'tasks_filtered', headerName: "Tasks Filtered", sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'events_sliced', headerName: "Events Sliced",  sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'tasks_sliced', headerName: "Tasks Sliced", sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'sample' ,  filter: true, hide: true},
    { field: 'filters' ,  filter: false, hide: true},
    { field: 'slicers' ,  filter: false, hide: true},
    { field: 'visualizer_settings' ,  filter: false, hide: true},
    { field: 'compliance_rules' ,  filter: false, hide: true},
    { field: 'automation_rules' ,  filter: false, hide: true},
    { field: 'cost_rules' ,  filter: false, hide: true},
    { field: 'event_log_id' ,  filter: false, hide: true},
  ])
  const groupDefaultExpanded = 1;
  const user = useAPI('/api/user');


  if (user.loading)
  return <Loader />

  return (
    <Animate>
      <div className={ Style.labelText }>Double click a process model to open the Process Explorer or right mouse click for more options...</div>
      <div className="ag-theme-alpine" style={{height: '600px', width: '100%'}}>
        <AgGridReact
          onFirstDataRendered={onFirstDataRendered}
          onRowDoubleClicked={doubleClick}
          onGridReady={onGridReady}
          rowData={models}
          columnDefs={columnDefs}
          sideBar={
            {
              toolPanels: [
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
              hiddenByDefault: false,
              defaultToolPanel: '',
            }
          }
          groupDefaultExpanded={groupDefaultExpanded}
          getContextMenuItems={getContextMenuItems}
          overlayLoadingTemplate={'Retrieving process models...'}
          overlayNoRowsTemplate={'No process models currently exist'}>
        </AgGridReact>
      </div>
    </Animate>
  );
}

const Style = {
  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block',
  labelText: 'inline-block mt-4 mb-1 text-normal',
}