/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import { Animate, Row, Content, Link } from 'components/lib'
import Style from './footer.tailwind.js';

export function Footer(props){

  return (
    <Animate type='slideup'>
      <footer className={ Style.footer }>
        <Row>
          <Content>

            <nav className={ Style.nav }>
              <Link url='/signin' text='Sign in' className={ Style.link } color='dark' />
              <Link url='/signup' text='Free Trial' className={ Style.link } color='dark' />              
              <Link url='/pricing' text='Pricing' className={ Style.link } color='dark' />
              <Link url='/purchase' text='Purchase' className={ Style.link } color='dark' />
              <Link url='/faq' text='FAQ' className={ Style.link } color='dark' />
              <Link url='/terms' text='Terms' className={ Style.link } color='dark' />
              <Link url='/privacy' text='Privacy' className={ Style.link } color='dark' />
              <Link url='/contact' text='Contact' className={ Style.link } color='dark' />
            </nav>
            <div className={ Style.copyright }>{ `Copyright © Metricus Pty Ltd ${new Date().getFullYear()}`}</div>
           
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}