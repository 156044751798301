import * as React from 'react';
import {QA} from './qa'
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import Typography from '@mui/material/Typography';

import {ExpandMore} from '@material-ui/icons';

export function FAQRequirements() {
return (
  <Accordion disableGutters={false}>
    <AccordionSummary
      expandIcon={<ExpandMore style={{ fill: 'white' }}/>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
          backgroundColor: "#197BCD",
          color: "white",
          fontFamily: "Helvetica",
          fontWeight: "bold",
          mb: "10px"
      }}
      >
      <Typography>Requirements...</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <QA question='What browser is required?' answer='All modern browsers are supported'/>
    <QA question='What screen resolution is recommended' answer='The Metricus Process Miner is a highly interactive application that requires a minimum screen resolution of 1920x1200.  If your display does not support this resolution, simply zoom the browser window'/>
  </AccordionDetails>
  </Accordion>
);
}

//       <Typography>Data...</Typography>
