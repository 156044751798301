/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext, useState, useEffect} from 'react';
import { Animate, AuthContext, Row, Card, PaymentForm,  useNavigate, Event } from 'components/lib';

const useAPI = require('components/lib').useAPI;

function usePlans(){

  const [state, setState] = useState({ data: null, loading: false });
  const plans = useAPI('/api/account/plans');
  
  useEffect(() => {

    setState({ loading: true });

    // format plans
    if (plans?.data?.plans){

      let formatted = [];
      plans.data.plans.map(plan => {
        if (plan.name === 'Free') {
          let label = `${plan.name} (${plan.currency.symbol}${plan.price + ' ' + plan.currency.name}/${plan.interval})`
          return formatted.push({ value: plan.id, label: label, currency: plan.currency.name });
        }

      });
      setState({ data: { list: formatted, active: plans.data.active, raw: plans.data }, loading: false });

    }
  }, [plans]);

  return state;

}

export function SignupPlan(props){

  const context = useContext(AuthContext);
  const user = useAPI('/api/user');
  const navigate = useNavigate();

  const [selectedPlans, setSelectedPlans] = useState(['free'])
  const plans = usePlans()


  React.useEffect(() => {
    if (plans.data != undefined) {
      setSelectedPlans(plans.data.list)
    }
  }, [])

  if (!plans.data)
    return false;

  return(
    <Animate type='pop'>
      <Row title='Select Your Plan'>
        <Card loading={ false } restrictWidth center>

          <PaymentForm
            data={{
              plan: {
                label: 'Plan',
                type: 'select',
                options: plans.data.list,
                required: true,
                default: 'free'
              }
            }}
            url='/api/account/plan'
            method='POST'
            buttonText='Save Plan'
            callback={ res => {

              // save the plan to context, then redirect
              Event('selected_plan', { plan: res.data.plan });
              context.update({ plan: res.data.plan, subscription: res.data.subscription });
              navigate(res.data.onboarded ? '/eventlogs' : '/welcome');

            }}
          />

        </Card>
      </Row>
    </Animate>
  );
}
