import * as React from 'react';
import {QA} from './qa'
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import Typography from '@mui/material/Typography';

import {ExpandMore} from '@material-ui/icons';

export function FAQData() {
return (
  <Accordion disableGutters={false}>
    <AccordionSummary
      expandIcon={<ExpandMore style={{ fill: 'white' }}/>}
      aria-controls="panel1a-content"
      id="panel1a-header"
      sx={{
          backgroundColor: "#197BCD",
          color: "white",
          fontFamily: "Helvetica",
          fontWeight: "bold",
          mb: "10px"
      }}
      >
      <Typography>Data...</Typography>
  </AccordionSummary>
  <AccordionDetails>
  <QA question='What operational data is required?' answer='The Metricus Process Miner requires event log data in a CSV format.  Details of the fields and format can be found at the Metricus Help Site'/>
      <QA question='What operational data is saved to the Metricus server?' answer='There is no operational data saved unless you request to save an imported event log for future analysis.'/>
      <QA question='What personal data is stored when I create a subscription?' answer='Details on personal data stored can be found in the Privacy link'/>
      <QA question='Can data be anonymized?' answer='Yes, any event log field can be anonymized during the data transformation process'/>
      <QA question='For the purposes of event log limitations, what is the definition of an event?' answer='An event is a single instance of an activity for a task/case.   An event can have up to 10 task/case attributes and 10 event attributes'/>
  </AccordionDetails>
  </Accordion>
);
}

//       <Typography>Data...</Typography>
