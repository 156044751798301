const Style = {

  wrapper: 'h-metricus',
  nav: 'absolute top-1/2 -translate-y-1/2 right-0',
  logo: 'translate-y-1/2 ',
  transparent: 'bg-none bg-transparent',
  color: 'bg-brand-500',
  link: 'mr-5 last:mr-0 no-underline',
  button: 'inline-block',

}

export default Style;