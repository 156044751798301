import { Home } from 'views/website/home';
import { Purchase } from 'views/website/purchase';
import { Contact } from 'views/website/contact';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { FAQ} from 'views/website/faq';
import { Privacy } from 'views/website/privacy';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'Metricus Process Miner'
  },
  {
    path: '/purchase',
    view: Purchase,
    layout: 'home',
    title: 'Purchase'
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Plans and Pricing'
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions'
  },
  {
    path: '/faq',
    view: FAQ,
    layout: 'home',
    title: 'Frequently Asked Questions'
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact'
  },
]

export default Routes;