import { Visualizer } from 'views/processminer/visualizer';
import { EventLogs } from 'views/processminer/eventlogs';
import { Models } from 'views/processminer/models';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/visualizer',
    view: Visualizer,
    layout: 'app',
    permission: 'user',
    title: 'Visualizer'
  },
  {
    path: '/eventlogs',
    view: EventLogs,
    layout: 'app',
    permission: 'user',
    title: 'Event Logs'
  },
  {
    path: '/models',
    view: Models,
    layout: 'app',
    permission: 'user',
    title: 'Models'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
]

export default Routes;
