
import React from 'react';
import { Animate, Row, FAQData, FAQPricing, FAQSupport, FAQRequirements} from 'components/lib';

export function FAQ(props){

  return(
    <Animate type='pop'>
      <Row color='tint' align='center' title='Frequently asked questions'>
      </Row>
      <Row color='tint' align='center'>
          <FAQData/>
      </Row>
      <Row color='tint' align='center'>
          <FAQRequirements/>
      </Row>
      <Row color='tint' align='left'>
          <FAQSupport/>
      </Row>
      <Row color='tint' align='left'>
          <FAQPricing/>
      </Row>
    </Animate>
  )
}
