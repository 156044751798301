/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Message } from 'components/lib';

export function OnboardingView(props){

  const context = useContext(AuthContext);
  const views = [
    {
      name: 'Getting Started',
      description: `Welcome, ${context.user.name}, to the Metricus Process Miner`,
      component: <Welcome/>
    },
  ]

  if (context.permission.admin){
    views.push({

      name: 'Invite your team', 
      description: 'Share the value of Metricus with members of your team.',
      component: <InviteUsers/>,
      
    });
  }

  if (context.user.duplicate_user){
    views.unshift({

      name: 'Important!',
      description: '',
      component: <DuplicateUser/>

    })
  }

  return <Onboarding save onFinish='/eventlogs' views={ views }/>

}

function DuplicateUser(){

  return (
    <Message
      type='warning'
      title={ `You already have an account` }
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />    
  )
}

function Welcome(){

  return (
    <Fragment>

      <p>The best way to get started is to check out one of the sample event logs and work through the tutorials at   <a target="_blank" href="https://metricus.com/tutorials/ "><u>Metricus Video TutorialsPortal.</u></a></p>
      <p>There's also online help available at <a target="_blank" href="https://support.metricus.com"><u>Metricus Process Miner Help and Support</u></a></p>
      <p>A quick note:  it's best to use the Process Miner with a minimum screen resolution of 1920x1200 </p>
      <p>Any questions or issues, please don't hesitate to contact Metricus through the <a target="_blank" href="https://support.metricus.com"><u>Support Portal.</u></a> </p>
        
    </Fragment>
  )
}


function InviteUsers(props){

  return (
    <Form 
      data={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}