/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import { Row, Button, Hero, Features, Testimonial } from 'components/lib';
import { Signin } from '../auth/signin/index';

export function Home(props){

  return(
      <Signin/>
  );
}
