/***
*
*   PROFILE
*   Update the user profile or close the account
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, ViewContext, Form, Card,
  AccountNav, Button, useNavigate, Animate, useAPI, Event } from 'components/lib';

  const countries = [
    { label: 'Argentina', value: 'AR' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Brazil', value: 'BR' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Canada', value: 'CA' },
    { label: 'Chile', value: 'CL' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Egypt', value: 'EG' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'Germany', value: 'DE' },
    { label: 'Greece', value: 'GR' },
    { label: 'Hong Kong SAR China', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Japan', value: 'JP' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Malta', value: 'MT' },
    { label: 'Mexico ', value: 'MX' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Norway', value: 'NO' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Romania', value: 'RO' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Trinidad & Tobago', value: 'TT' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'Uruguay', value: 'UY' }
  ]


export function Profile(props){

  const navigate = useNavigate();
  
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/api/user');

  function closeAccount(){
    viewContext.modal.show({

      title: 'Close Your Account',
      form: {},
      buttonText: 'Close Account',
      url: authContext.permission.owner ? '/api/account' : '/api/user',
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete your account? ' +
      'You will lose all of your data and this can not be undone.',

    }, () => {

      // destory user
      Event('closed_account');
      localStorage.clear();
      navigate('/signup');

    });
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card
          title='Edit Your Profile'
          loading={ user.loading } restrictWidth>

          { user?.data &&
            <Form
              buttonText='Save'
              url='/api/user'
              method='PATCH'
              data={{
                name: {
                  label: 'Your Name',
                  type: 'text',
                  required: true,
                  value: user.data.name,
                  errorMessage: 'Please enter your name',
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: user.data.email,
                  errorMessage: 'Please enter your email address'
                },
                ...user.data.permission === 'owner' && {
                  account_name: {
                    label: 'Organization',
                    type: 'text',
                    required: true,
                    value: user.data.account_name,
                    errorMessage: 'Please enter the name of your organisation'
                  },
                  address1: {
                    label: 'Address 1',
                    type: 'text',
                    required: true,
                    value: user.data.address1,
                    errorMessage: 'Please enter an address'
                  },
                  address2: {
                    label: 'Address 2',
                    type: 'text',
                    value: user.data.address2,
                    required: false,
                  },
                  city: {
                    label: 'City',
                    type: 'text',
                    required: true,
                    value: user.data.city,
                    errorMessage: 'Please enter an city'
                  },
                  state: {
                    label: 'State',
                    type: 'text',
                    required: true,
                    value: user.data.state,
                    errorMessage: 'Please enter an state'
                  },
                  postal_code: {
                    label: 'Postal Code',
                    type: 'text',
                    required: true,
                    value: user.data.postal_code,
                    errorMessage: 'Please enter a postal code'
                  },
                  country: {
                    label: 'Country',
                    type: 'select',
                    options: countries,
                    required: true,
                    default: user.data.country,
                    errorMessage: 'Please select a country'
                  },
                }
              }}
              callback={ res => {

                const accounts = [...authContext.user.accounts]
                accounts[accounts.findIndex(x => x.id === authContext.user.account_id)].name = res.data.data.account_name;
                authContext.update({ accounts: accounts })

                // update the user name
                authContext.update({ name: res.data.data.name })

              }}
            />
          }

          <Fragment>
            <br/>
            <Button
              textOnly
              action={ closeAccount }
              text='Close Your Account'
            />
          </Fragment>
        </Card>
      </Animate>
    </Fragment>
  );
}
