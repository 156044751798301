import React from 'react';
import { useSearchParams } from "react-router-dom";
import { useAPI } from 'components/lib';
import { MetricusContainer } from '@metricus/frontend';

export function Visualizer(props) {

  const user = useAPI('/api/account');
// appears to be executing multiple times
  var planTier = ''
  if (user.data) {planTier = user.data.plan_tier}
  let [searchParams, setSearchParams] = useSearchParams();
  let eventLogID = searchParams.get("event_log_id");
  let modelID = searchParams.get("model_id");
  let sample = searchParams.get("sample");
  return (
    <MetricusContainer eventLogID={eventLogID} modelID={modelID} planTier={planTier} sample={sample} />
  );
}

