import React, { useState , useEffect} from 'react';
import { Animate, Row, Card, Form, Message, useNavigate} from 'components/lib';
const useAPI = require('components/lib').useAPI;

function usePlans(){

  const [state, setState] = useState({ data: null, loading: false });
  const plans = useAPI('/api/account/plans');
  
  useEffect(() => {

    setState({ loading: true });

    // format plans
    if (plans?.data?.plans){

      let formatted = [];
      plans.data.plans.map(plan => {
        if (plan.name != 'Free') {
          let label = `${plan.name} (${plan.currency.symbol}${plan.price + ' ' + plan.currency.name}/${plan.interval})`
          return formatted.push({ value: plan.id, label: label, currency: plan.currency.name });
        }

      });
      setState({ data: { list: formatted, active: plans.data.active, raw: plans.data }, loading: false });

    }
  }, [plans]);

  return state;

}

export function PurchaseForm(props){

  const navigate = useNavigate();
  const [selectedPlans, setSelectedPlans] = useState([])
  const plans = usePlans()
  const [sent, setSent] = useState(false);

  React.useEffect(() => {
    if (plans.data != undefined) {
      setSelectedPlans(plans.data.list)
    }
  }, [])

  if (!plans.data)
    return false;

  const countries = [
    { label: 'Argentina', value: 'AR' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Brazil', value: 'BR' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Canada', value: 'CA' },
    { label: 'Chile', value: 'CL' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Egypt', value: 'EG' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'Germany', value: 'DE' },
    { label: 'Greece', value: 'GR' },
    { label: 'Hong Kong SAR China', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Japan', value: 'JP' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Malta', value: 'MT' },
    { label: 'Mexico ', value: 'MX' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Norway', value: 'NO' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Romania', value: 'RO' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Trinidad & Tobago', value: 'TT' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'Uruguay', value: 'UY' }
  ]

  return (
    <Animate type='pop'>
      <Row title='Purchase Request'>
        <Card loading={ false } restrictWidth center>


      { sent ? 
        <Message  
          title='Purchase Request Sent'
          type='success'
          text={ `Thank you for your purchase request, we'll be in touch as soon as possible.` }
        />  :

        <Form 
          data={{ 
            plan: {
              label: 'Plan',
              type: 'select',
              options: plans.data.list,
              required: true,
            },
            name: {
              label: 'Your name',
              type: 'text',
              required: true,
              errorMessage: 'Please enter your name'
            },
            email: {
              label: 'Your email',
              type: 'email',
              required: true,
              errorMessage: 'Please enter your email address'
            },
            account: {
              label: 'Organization',
              type: 'address',
              required: true,
              errorMessage: 'Please enter the name of your organisation'
            },
            address1: {
              label: 'Address 1',
              type: 'address',
              required: true,
              errorMessage: 'Please enter an address'
            },
            address2: {
              label: 'Address 2',
              type: 'address',
              required: false,
            },
            city: {
              label: 'City',
              type: 'address',
              required: true,
              errorMessage: 'Please enter an city'
            },
            state: {
              label: 'State',
              type: 'address',
              required: true,
              errorMessage: 'Please enter an state'
            },
            postal_code: {
              label: 'Postal Code',
              type: 'address',
              required: true,
              errorMessage: 'Please enter a postal code'
            },
            country: {
              label: 'Country',
              type: 'country',
              options: countries,
              required: true,
              errorMessage: 'Please select a country'
            },

          }}
          method='POST'
          url='/api/utility/purchase'
          callback={ e => setSent(true) }
          buttonText='Send Request'
         />
       }
        </Card>
      </Row>
    </Animate>
  );
}
