/***
*
*   LOGO
*   Replace the image in /images with your own logo
*
**********/

import React from 'react';
import { Link, ClassHelper } from 'components/lib';
import LogoHeader from './images/MetricusProcessMiner.svg';
import LogoBlack from './images/ProcessMiner.svg';
import Style from './logo.tailwind.js';


export function Logo(props){

  const Logo = {
      header: LogoHeader,
      black: LogoBlack,
      white: LogoBlack,
  }

  const logoStyle = ClassHelper(Style, props);
  return(
    <Link url='/' className={ logoStyle }>
      <img src={ Logo[props.logo] } alt='Logo' />
    </Link>
  )
}
