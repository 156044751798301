import {React, useState, useContext, useCallback} from 'react';
import { saveAs } from 'file-saver'
import Axios from 'axios';
import {ViewContext, Animate, useAPI, useNavigate, Event, Loader, Button, Row, Link, Label} from 'components/lib';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/dist/styles/ag-grid.css';
import 'ag-grid-enterprise/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import './visualizer.css'
import FeatherIcon from 'feather-icons-react';

const Wrapper = styled.div`
    height: 100%;
    width: 100%;    
    margin-bottom: 5px;
`;

var oldData = []
var data = []

export function EventLogs(props){

  const getContextMenuItems = useCallback((params) => {
    var result = [

      {
        // custom item
        name: 'Visualizer',
        action: function () {
          navigate({pathname: '/visualizer', search: '?event_log_id=' + params.node.data.id + '&sample=' + params.node.data.sample})
        },
        icon:
          '<img src="https://metricuspm.blob.core.windows.net/processminer/PO_Explorer.png" />',
      },
      {
        // custom item
        name: 'Download',
        action: function () {
          DownloadEventLog(params.node.data.id)
        },
        icon:
          '<img src="https://metricuspm.blob.core.windows.net/processminer/PO_Download.png"/>',
      },
      {
        // custom item
        name: 'Delete',
        action: function () {
          DeleteEventLog(params.node.data.id);
        },
        icon:
          '<img src="https://metricuspm.blob.core.windows.net/processminer/PO_Delete.png"/>',
      },
      'separator',
      'export'
    ];
    return result;
  }, []);
  
  function dateFormatter(params) {
    if (!params.value) {return}
    var dateAsString = params.data.date_created;
    var year = dateAsString.substring(0,4)
    var month = dateAsString.substring(5,7)
    var day = dateAsString.substring(8,10)
    var hour = dateAsString.substring(11,13)
    var minute = dateAsString.substring(14,16)
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute;
  }

  function integerFormatter(params) {
    var x = params.value
    if (!x) {return}
    return Number(x).toLocaleString()
  }

  const onFirstDataRendered  = (params) => {
    const allColumnIds = [];
    params.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeAllColumns();
  };

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  }


  async function DownloadEventLog(eventLogID){
    const pako = require('pako');
    // reveal the api key
    const url = '/api/eventlog/retrieve_eventlog/' + eventLogID;
    const result = (await Axios.get(url));

    const file = pako.inflate(_base64ToArrayBuffer(result.data.data.eventLog[0].event_log), { to: 'string' })
    let fileName = result.data.data.eventLog[0].name + '_event_log.csv';
    let fileToSave = new Blob([file], {
        type: 'application/text',
        name: fileName
    });
    saveAs(fileToSave,fileName)
  }
   


  function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;

  }
  const doubleClick = (params) => {
    navigate({pathname: '/visualizer', search: '?event_log_id=' + params.node.data.id + '&sample=' + params.node.data.sample})
  }

  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);

  const DeleteEventLog = (eventLogID) => {

    viewContext.modal.show({
      title: 'Delete Event Log',
      form: {},
      buttonText: 'Delete',
      url: `/api/eventlog/delete/${eventLogID}`,
      method: 'DELETE',
      destructive: true,
      text: 'Are you sure you want to delete the event log? ' 

    }, () => {
  
      for (var i = 0; i < data.data.eventLogs.length; i++) {
        if (data.data.eventLogs[i].id === eventLogID) {
          data.data.eventLogs.splice(i,1)
        }
      }
  
    });

  }
  
  const rows = [];
  data = useAPI('/api/eventlog/retrieve_all')
 
  if (data.data !== null && data.data !== undefined) {
    for (var i = 0; i < data.data.eventLogs.length; i++) {
      rows.push({
        id: data.data.eventLogs[i].id,
        category: data.data.eventLogs[i].category,
        description: data.data.eventLogs[i].description,
        name: data.data.eventLogs[i].name,
        owner_name: data.data.eventLogs[i].owner_name,
        date_created: data.data.eventLogs[i].date_created,
        events: data.data.eventLogs[i].events,
        entity: data.data.eventLogs[i].entity,
        tasks: data.data.eventLogs[i].tasks ,
        sample: data.data.eventLogs[i].sample ,
        process_analytics: 1 ,     
      });
    }
  }
  rows.sort((a, b) => a.category.localeCompare(b.category) || a.name.localeCompare(b.name))
  oldData = data

  const [columnDefs] = useState([
    { field: 'id', hide: true,  },
    { field: 'category', rowGroup: true, sortable: true, hide: true},
    { field: 'name', headerName: 'Name', sortable: true, filter: true, resizable: true },
    { field: 'description', headerName: 'Description', sortable: true, filter: true, resizable: true },
    { field: 'entity', headerName: 'Process Entity', sortable: true, filter: true, resizable: true },
    { field: 'owner_name' , headerName: 'Owner', sortable: true, filter: true, resizable: true },
    { field: 'date_created', headerName: 'Create Time' , valueFormatter: dateFormatter, sortable: false, filter: true, resizable: true},
    { field: 'events' ,  sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'tasks' ,  sortable: true, filter: true, valueFormatter: integerFormatter, resizable: true , filter: 'agNumberColumnFilter'},
    { field: 'sample' ,  filter: true, hide: true},
  ])
  const groupDefaultExpanded = 1;
  const user = useAPI('/api/user');

  function visualizer(){
    navigate({pathname: '/visualizer'})
  }

  if (user.loading)
  return <Loader />

  return (
    <Animate>
      <Button text='Import event log' action={ e => visualizer() }/>
      <div className={ Style.labelText }>Double click an event log to open the Process Visualizer or right mouse click for more options...</div>
      <div className="ag-theme-alpine" style={{height: '600px', width: '1200px'}}>
        <AgGridReact
          onFirstDataRendered={onFirstDataRendered}
          onRowDoubleClicked={doubleClick}
          onGridReady={onGridReady}
          rowData={rows}
          columnDefs={columnDefs}
          sideBar={
            {
              toolPanels: [
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                },
              ],
              hiddenByDefault: false,
              defaultToolPanel: '',
            }
          }
          groupDefaultExpanded={groupDefaultExpanded}
          getContextMenuItems={getContextMenuItems}
          overlayLoadingTemplate={'Retrieving event logs...'}
          overlayNoRowsTemplate={'No event logs currently exist'}>
        </AgGridReact>
      </div>
    </Animate>
  );
}

const Style = {
  subtitle: 'font-bold mt-4',
  link: 'text-blue-500 font-semibold mt-3 inline-block',
  labelText: 'inline-block mt-4 mb-1 text-normal',
}