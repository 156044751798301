import * as React from 'react';
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import Typography from '@mui/material/Typography';
import {ExpandMore} from '@material-ui/icons';

export function QA(props){
  return (
    <Accordion disableGutters={false}>
        <AccordionSummary
            expandIcon={<ExpandMore style={{ fill: '#197BCD' }}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
                backgroundColor: "white",
                color: "#197BCD",
                fontFamily: "Helvetica",
                fontWeight: "bold",
                mb: "10px"
            }}
            >
            <Typography>{props.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography align="left">
                {props.answer}
            </Typography>
        </AccordionDetails>
    </Accordion>
  );
}